import { default as clsx } from "classnames";
import type { RefObject } from "react";
import { useEffect, useMemo, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { twMerge } from "tailwind-merge";

export { ClientOnly };

export function cn(...inputs: clsx.ArgumentArray) {
  return twMerge(clsx(inputs));
}

export default function useOnScreen(ref?: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => {
      if (typeof window === "undefined") return;
      return new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref],
  );

  useEffect(() => {
    if (!ref?.current) return;
    observer?.observe(ref.current);
    return () => observer?.disconnect();
  }, [ref, observer]);

  return isIntersecting;
}

export function useIsOffScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => {
      if (typeof window === "undefined") return;
      if (!ref) return;

      return new IntersectionObserver(([entry]) => {
        const isElementOffScreen =
          !entry.isIntersecting && entry.boundingClientRect.top < 0;

        setIntersecting(isElementOffScreen);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref],
  );

  useEffect(() => {
    if (!ref?.current) return;
    observer?.observe(ref.current);
    return () => observer?.disconnect();
  }, [ref, observer]);

  return isIntersecting;
}

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return debouncedValue;
};
